import React, { Component } from 'react';
import { Container, Button, Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Popup from '../../components/Popup/Popup';
import HeaderImprint from '../../components/Header/HeaderImprint';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actionCreator from '../../redux-store/redux-actions/index';
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
// End Redux
import { ProductDetail } from '../../api/index';


import Moment from 'react-moment';



class DetailProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: {},
            isLoading: false,

            currentImageIndex: 0,
            intervalId: null,

            main_picture: null,
            isPopUpOpen: false,
            CurrentQty: null,
            newQty: null,
            isExpanded: false, //buat more info
            showAllAuthors: false,

            main_picture: null,
            thumbsSwiper: null,
           
        }

        this.topPageref = React.createRef();
        this.toggleDescription = this.toggleDescription.bind(this); // Tambahan: Bind fungsi
        this.toggleAuthors = this.toggleAuthors.bind(this);
    }

    // componentWillUpdate(){
    //     const CurrentQty = this.props.order[this.state.product.id];
    //     console.log(CurrentQty)
    //     // this.setState({CurrentQty:CurrentQty})
    // }

    componentWillReceiveProps(nextProps) {

        if (nextProps.match.params.id !== this.props.match.params.id) {
            const idProduct = nextProps.match.params.id.split("-")[0];
            this.dataLoadingInit(idProduct);
            // console.log("rerender")
        }

        this.setState({
            CurrentQty: nextProps.order[this.state.product.id],
            newQty: nextProps.order[this.state.product.id]

        })
    }



    componentDidMount() {
        const idProduct = this.props.match.params.id.split("-")[0];
        this.dataLoadingInit(idProduct);

        // Auto-slide tiap 3 detik
        const intervalId = setInterval(() => {
            this.setState((prevState) => {
                const newIndex = (prevState.currentImageIndex + 1) % prevState.product.Pictures.length;
                return {
                    currentImageIndex: newIndex,
                    main_picture: prevState.product.Pictures[newIndex].url_medium
                };
            });
        }, 3000);

        /// supaya scroll ke atas
        window.scrollTo(0, 0);
        // console.log(this.topPageref)
        // this.topPageref.current.scrollTo(0, 0);        
    }
        componentWillUnmount() {
            clearInterval(this.state.intervalId);
        }

    dataLoadingInit(idProduct) {
        this.setState({ isLoading: true })
        // console.log(idProduct)
        const content = {
            ProductId: idProduct
        }
        ProductDetail(content).then(res => {
            // console.log(res)
            this.setState({
                product: res,
                isLoading: false,
                main_picture: res.Pictures[0].url_medium,
                CurrentQty: this.props.order[res.id],
                newQty: this.props.order[res.id]
            });
        })
    }

    changeMainPictureHandler = (event, value) => {
        event.preventDefault();
        this.setState({ main_picture: value.url_medium })
    }

    togglePopup = (event) => {
        event.preventDefault();
        this.setState({ isPopUpOpen: false })
    }

    buyNowHandler = (event) => {
        this.setState({ isPopUpOpen: true })
        this.props.IncOrder(this.state.product.id, this.props.order)

    }
    toggleDescription() {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded,
        }));
    }
    
    toggleAuthors = () => {
        this.setState((prevState) => ({ 
            showAllAuthors: !prevState.showAllAuthors
        }));
    };

    inputChangeHandler = (event) => {
        event.preventDefault();
        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/,/g, '');

        let isNum = /^\d+$/.test(values); // tanpakoma
        //  let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                 

        if (isNum || event.target.value === null) {
            this.setState({ newQty: parseFloat(values) })
        }
        else if (values.length <= 1) {
            this.setState({ newQty: 0 })
        }

        // this.setState({newQty:event.target.value})
    }

    incOrderHandler = (event, productId, ProductListFromStore) => {
        event.preventDefault();
        const oldQty = this.state.newQty;
        const newQty = oldQty + 1;
        this.setState({ newQty: newQty });
        // this.props.IncOrder(productId, ProductListFromStore)
    }

    decOrderHandler = (event, productId, ProductListFromStore) => {
        event.preventDefault();
        const oldQty = this.state.newQty;
        const newQty = oldQty - 1;
        if (this.state.newQty > 1) {
            this.setState({ newQty: newQty });
        } else {
            console.log(ProductListFromStore)
            this.props.DeleteOrder(productId)
        }
    }

    updateQuantityHandler = (event, productId, qty) => {
        this.props.UpdateOrder(productId, qty);
        this.setState({ CurrentQty: qty })
    }

    nextImage = () => {
        this.setState((prevState) => {
            const newIndex = (prevState.currentImageIndex + 1) % prevState.product.Pictures.length;
            return {
                currentImageIndex: newIndex,
                main_picture: prevState.product.Pictures[newIndex].url_medium
            };
        });
    };
    
    prevImage = () => {
        this.setState((prevState) => {
            const newIndex = (prevState.currentImageIndex - 1 + prevState.product.Pictures.length) % prevState.product.Pictures.length;
            return {
                currentImageIndex: newIndex,
                main_picture: prevState.product.Pictures[newIndex].url_medium
            };
        });
    };
    
    
    

    render() {
        let price = null;
        let headerPromo = null;
        let authors = null;
        let authorsString = "blst";
        let categories = null;
        let categoriesString = "blst";
        let picture_navigation =
            <ul className="small-img-wrapper">
                
                <li>
                    <div className="thbn-prod loading-background" ></div>
                </li>
                <li>
                    <div className="thbn-prod loading-background" ></div>
                </li>
            </ul>
        let picture_main = <div className="prmry-thmb loading-background"></div>


        if (this.state.product.Pictures && this.state.product.Pictures.length > 0) {
            picture_navigation = <ul className="small-img-wrapper">
                {this.state.product.Pictures.map((value, key) => {
                    return <li key={key}>
                        <div className="thbn-prod contain-pict"
                            onMouseEnter={(event) => this.changeMainPictureHandler(event, value)}
                            style={{
                                background: `url(${value.url_small})`,

                            }} ></div>
                    </li>
                })}
            </ul>

            picture_main = <div className="prmry-thmb contain-pict" style={{
                background: `url(${this.state.main_picture})`,
            }}></div>;

            authorsString = [];

            authors = this.state.product.Authors.map((value, index) => {
                authorsString.push(value.name)

             return   <div className="author-card" key={index}>{value.name}</div>
            })

            categoriesString = [];

             categories = this.state.product.Categories.map((value, index) => {
                categoriesString.push(value.name)
            
                return   <div className="categorie-card" key={index}>{value.name}</div>

             })
        }


        if (this.state.product.promoPercentage && this.state.product.promoPercentage !== null || this.state.product.promoPercentage > 0) {
            headerPromo =
                <div className="hfsbn">         
                </div>
            price =
                <div>
                    <span className="product-price__reduced">
                        <NumberFormat className="ori-price" value={this.state.product.base_price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                        <div className="ppd-off">{this.state.product.promoPercentage}% off</div>
                    </span>
                    <span className="product-price__original">
                        <strong>
                            <NumberFormat value={this.state.product.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                        </strong>

                    </span>
                </div> 
        }

        else if(this.state.product.status_books == 1 & this.state.product.promoPrice > 4 ) {
                headerPromo =
                <div className="hfsbn-jurnal">
                    <span> <i></i>Article Publication Charge</span>
                </div>
            price =
                <div>
                    <span className="product-price__original">
                        <strong>
                            <NumberFormat value={this.state.product.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                        </strong>

                    </span>
                </div> 
        } else if (this.state.product.promoPrice == 3 & this.state.product.promoPrice < 4) {
            headerPromo =
            <div className="hfsbn-jurnal">
                <span> <i></i>Article Publication Charge</span>
                
            </div>
            
            
        price =
            <div>
                <span className="product-price__original">
                    <strong>
                        Free
                    </strong>

                </span>
            </div> 

        }
        else if (this.state.product.penerbitId !== null ){
            price =
                <span className="product-price-imprint">
                    <NumberFormat value={this.state.product.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                </span>
        }

        
        else {
            price =
                <span className="product-price">
                    <NumberFormat value={this.state.product.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                </span>
        }


        let popup_body =
            <div className="popup_displayproduct">
                <div className="displayProduct background-contain contain-pict" style={{ background: `url(${this.state.product.Pictures ? this.state.product.Pictures[0].url_small : ''})` }}></div>
                <div className="ProductTitle">
                    <span>{this.state.product.name}</span>
                </div>
            </div>
            
        let description = this.state.product.description || "";
        const shortDescription = description.length > 60
            ? `${description.substring(0, 60)}...`
            : description;    

        return (<>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.product.name}</title>
                <meta name="description" content={this.state.product.description}></meta>
                <meta name="author" content={authorsString.toString()}></meta>
                <meta name="categorie" content={categoriesString.toString()}></meta>
                <meta property="image" content={this.state.main_picture}></meta>

                <meta property="og:title" content={this.state.product.name}></meta>
                <meta property="og:url" content={"https://ipbpress.com/product/"+this.state.product.id+"-"+this.state.product.name}></meta>
                <meta property="og:image" content={this.state.main_picture}></meta>
                <meta property="og:author" content={authorsString.toString()}></meta>
                <meta name="categorie" content={categoriesString.toString()}></meta>
            </Helmet>

            <Container>
                {/* Render HeaderImprint hanya jika penerbitId tidak null */}
            {this.state.product.penerbitId !== null ? <HeaderImprint /> : null}
            
                <Popup
                    onClosePopupHandler={this.togglePopup}
                    isOpen={this.state.isPopUpOpen}
                    headerTitle={"Berhasil Ditambahkan"}
                    body={popup_body}
                />
                <div className="product-detail-wrapper" ref={this.topPageref}>
                    <div className="inner-product-detail">
                    <div className="product-image-wrapper"
                            
                            >
                                <div className="viewers-image">
                                <button onClick={this.prevImage} className="nav-button left"> <span className="arrow"></span></button>
                                    <img 
                                        src={this.state.product.Pictures ? this.state.product.Pictures[this.state.currentImageIndex].url_medium : ""}
                                        alt="Main Product"
                                        className="viewer-image"
                                    />
                                    <button onClick={this.nextImage} className="nav-button right"> <span className="arrow"></span></button>
                                </div>
                            </div>
                        <div className="ptw">

                            {/* Judul */}
                            <div className="pdh">
                                <h1>{this.state.product.name}</h1>
                            </div> 

                            {/* Menampilkan Penulis di bawah judul jika bukan jurnal */}
                                {this.state.product.status_books !== 1 && authors && Array.isArray(authors) && (
                                    <div className="pd-author">
                                         <span className="label">Penulis :</span>
                                         <div className="name-container">
                                            {/* Jika showAllAuthors true, tampilkan semua penulis */}
                                            {this.state.showAllAuthors
                                                ? authors.map((author, index) => (
                                                    <span key={index} className="name">{author}</span>
                                                ))
                                                : authors.slice(0, 3).map((author, index) => (
                                                    <span key={index} className="name">{author}</span>
                                                ))
                                            }
                                            {authors.length > 3 && !this.state.showAllAuthors && (
                                                <button
                                                    className="show-more-authors"
                                                    onClick={this.toggleAuthors}
                                                >
                                                    Lihat Semua Penulis
                                                </button>
                                            )}
                                        </div>
                                        
                                    </div>
                                    )}

                             {this.state.product.promoPrice > 4 & this.state.product.status_books == null  ?
                            <div className="prc-ctn">
                            {headerPromo}
                            <div className="prc-wrp">
                                {price}
                            </div>
                        </div> : null
                        
                        } 

                            {/* Action Button */}
                            <div className="cta-wrapper">

                                {
                                    // halaman jurnal
                                    (this.state.product.status_books == 1) ? (
                                        <>
                                        <div className="pd-author-jurnal">
                                            <div className="top-jurnal">
                                                <span className="title">Penerbit :</span>
                                                <span className="publisher">{this.state.product.publisher}</span>
                                            </div>
                                        
                                            {/* Promo */}
                                            <div className="prc-ctn">
                                            {headerPromo}
                                            <div className="prc-wrp">
                                            {price}

                                            </div>
                                            </div>
                                            
                                            {/* Pilihan */}
                                            <Button className="cta-btn-jurnal"  onClick={(e) => {
                                            e.preventDefault();
                                            window.open(this.state.product.link_journal);
                                            }}>
                                                <span>View Article</span>
                                            </Button> <br/>
                                            <br/>
                                            
                                            <Button className="cta-btn-jurnal"  onClick={(e) => {
                                            e.preventDefault();
                                            window.open(this.state.product.url_submit);
                                            }}>
                                                <span>Submit Article</span>
                                            </Button>
    
                                            
                                        </div>
                                        </>
                                    ) : 


                                    
                                    (!this.props.order[this.state.product.id]) ?
                                        (
                                            <>
                                         {this.state.product.promoPrice !== 1 ? (
                                                  <Button 
                                                  className= {this.state.product.penerbitId !== null ? "cta-penerbit" : "cta-btn" }
                                                  onClick={(event) => { this.buyNowHandler(event) }} >
                                                  <span>Beli Sekarang</span>
                                              </Button> 
                                            ): 
                                            <div className="kerjasama-wrapper">
                                            <div className="pd-author">
                                                <div className="info-item">
                                                    <div className="title"><strong>Penerbit</strong></div>
                                                    <div>{this.state.product.publisher}</div>
                                                </div>
                                                <div className="info-item">
                                                    <div className="title"><strong>Bahasa</strong></div>
                                                    <div>{this.state.product.language}</div>
                                                </div>
                                            </div>
                                        
                                            <div className="pd-author">
                                                <div className="info-item">
                                                    <div className="title"><strong>ISBN</strong></div>
                                                    <div>{this.state.product.isbn}</div>
                                                </div>
                                                <div className="info-item">
                                                    <div className="title"><strong>Kategori</strong></div>
                                                    <div>{categories}</div>
                                                </div>
                                            </div>
                                        
                                        
                                               </div> }           
                                            </>
                                        ) :
                                        (
                                            <div className="qty-rwp">
                                                {/* <span>jumlah </span> */}
                                                <div className="hdl-prod-wrp">
                                                      {/* minus */}
                                                    <div className="chg-qty-btn minus" onClick={(event) => { 
                                                        this.decOrderHandler(event, this.state.product.id, this.props.order);
                                                        this.updateQuantityHandler(event, this.state.product.id, this.state.newQty - 1); 
                                                        }}>
                                                        <i className="fas fa-minus"></i>
                                                    </div>
                                                    {/* ubah jumlah */}
                                                    <Input className="input-chg-qty"
                                                        // value={this.props.order[this.state.product.id]} 
                                                        value={this.state.newQty}
                                                        onChange={(event) => { 
                                                            this.inputChangeHandler(event)
                                                            this.updateQuantityHandler(event, this.state.product.id, event.target.value); 
                                                        }}
                                                        type="text" />
                                                        {/* plus */}
                                                    <div className="chg-qty-btn plus" onClick={(event) => {
                                                         this.incOrderHandler(event, this.state.product.id, this.props.order);
                                                         this.updateQuantityHandler(event, this.state.product.id, this.state.newQty + 1);
                                                        }}>
                                                        <i className="fas fa-plus"></i>
                                                    </div>
                                                </div>

                                            </div>
                                        )

                                }


                            </div>

                            {/* Lanjut Pembayaran */}
                            {(this.props.order[this.state.product.id]) ? (
                                <div className="checkout-wrapper">
                                    <div className="cond-and-term">
                                        *Dengan mengklik tombol di bawah, Anda menyetujui Syarat & Ketentuan dan Kebijakan Privasi
                                </div>
                                    <NavLink to="/checkout" style={{ textDecoration: 'none' }}>
                                        <Button className={this.state.product.penerbitId !== null ? "cta-penerbit" : "cta-btn-checkout" }>
                                            <span>Lanjut Pembayaran <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i></span>
                                        </Button>

                                    </NavLink>

                                    <div className="orback">
                                        atau, <strong onClick={() => { this.props.history.goBack() }} style={{ cursor: 'pointer' }}>kembali belanja</strong>
                                    </div>
                                </div>
                            ) : null

                            }

                        </div>
                        {/* end ptw */}

                    </div>

                    {
                        (this.state.product.status_books == 1) ? (
                            <div className="inner-product-detail">
                            <div className="pdesc-wrapper">
                                <div className="pd-phy">
                                    <div className="pdescw">
                                        <div className="ptitle">P-ISSN</div>
                                        <div className="pvalue">{this.state.product.isbn}</div>
                                    </div>
                                    <div className="pdescw">
                                        <div className="ptitle">E-ISSN</div>
                                        <div className="pvalue">{this.state.product.eisbn}</div>
                                    </div>
                                    <div className="pdescw">
                                        <div className="ptitle">Akreditasi</div>
                                        <div className="pvalue">{this.state.product.akreditasi}</div>
                                    </div>
                                    <div className="pdescw">
                                        <div className="ptitle">Edisi</div>
                                        <div className="pvalue">{this.state.product.edisi_jurnal}</div>
                                    </div>
                                    {/* <div className="pdescw">
                                        <div className="ptitle">Halaman</div>
                                        <div className="pvalue">{this.state.product.pages}</div>
                                    </div> */}
                                    <div className="pdescw">
                                        <div className="ptitle">Tanggal Terbit</div>
                                        <div className="pvalue"><Moment locale="id" format="DD-MMMM-YYYY">{this.state.product.publish_date}</Moment></div>
                                    </div>
                                    <div className="pdescw">
                                        <div className="ptitle">Bulan Terbit</div>
                                        <div className="pvalue">{this.state.product.month_jurnal}</div>
                                    </div>
                                </div>
                                <div className="pd-desc">
                                    <div className="title"><strong>Ruang Lingkup</strong></div>
                                    <p>
                                    {this.state.product.description
                                        ? this.state.isExpanded
                                            ? this.state.product.description.replace(/#isbn/g, '')
                                            : `${this.state.product.description.substring(0, 100).replace(/#isbn/g, '')}...`
                                        : "Deskripsi tidak tersedia."}
                                    </p>
                                    <button onClick={this.toggleDescription}>
                                        {this.state.isExpanded ? "Lihat Lebih Sedikit" : "Lihat Lebih Banyak"}
                                    </button>
                                </div>
                            </div>
                        </div>
                            
                        ) :  <div className="inner-product-detail">
                        <div className="pdesc-wrapper">
                            <div className="pd-phy">
                                <div className="pdescw">
                                    <div className="ptitle">Berat</div>
                                    <div className="pvalue">{this.state.product.weight} g</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">panjang</div>
                                    <div className="pvalue">{this.state.product.height} cm</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">lebar</div>
                                    <div className="pvalue">{this.state.product.width} cm</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">Halaman</div>
                                    <div className="pvalue">{this.state.product.pages}</div>
                                </div>
                                <div className="pdescw">
                                    <div className="ptitle">Tahun Terbit</div>
                                    <div className="pvalue">{new Date(this.state.product.publish_date).getFullYear().toString()}</div>
                                </div>
                            </div>
                            <div className="pd-desc">
                                <div className="title"><strong>Deskripsi</strong></div>
                                <p>
                                {this.state.product.description
                                    ? this.state.isExpanded
                                        ? this.state.product.description.replace(/#isbn/g, '')
                                        : `${this.state.product.description.substring(0, 300).replace(/#isbn/g, '')}...`
                                    : "Deskripsi tidak tersedia."}
                                </p>
                                <button onClick={this.toggleDescription}>
                                    {this.state.isExpanded ? "Lihat Lebih Sedikit" : "Lihat Lebih Banyak"}
                                </button>
                                
                            </div>
                        </div>
                    </div>
                    }

                   

                    
                </div>

            </Container>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        order: state.order
    }
}

const mapDispatchToProps = dispatch => {
    return {
        IncOrder: (idProduct, productlist) => dispatch(actionCreator.incOrderHandler(idProduct, productlist)),
        DecOrder: (idProduct, productlist) => dispatch(actionCreator.decOrderHandler(idProduct, productlist)), // ga dipakai

        // new
        UpdateOrder: (ProductId, qty) => dispatch(actionCreator.updateOrderHandler(ProductId, qty)),
        DeleteOrder: (ProductId) => dispatch(actionCreator.deleteOrderHandler(ProductId))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailProduct));