import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HeaderImprint from "../../components/Header/HeaderImprint";
import DetailImprint from "../DetailProduct/DetailImprint";

const Imprint = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const dummyData = Array.from({ length: 60 }, (_, i) => ({
    id: i + 1,
    title: `Buku ${i + 1}`,
    price: Math.floor(Math.random() * 100000) + 20000, // Harga acak
    image: "https://example.com/book-cover.png", // Ganti URL gambar buku dari luar
  }));

  const bannerImages = [
    "https://res.cloudinary.com/dhitaaaa/image/upload/v1741311239/samples/landscapes/architecture-signs.jpg",
    "https://res.cloudinary.com/dhitaaaa/image/upload/v1741311249/samples/coffee.jpg",
    "https://res.cloudinary.com/dhitaaaa/image/upload/v1741311240/samples/food/spices.jpg",
  ];

  useEffect(() => {
    setProducts(dummyData);
    setFilteredProducts(dummyData);
  }, []);

  const handleFilter = () => {
    let filtered = products;
    // Tambahkan logika filter di sini jika perlu
  };

  return (
    <div className="imprint-page">
      <div className="imprint">
        <HeaderImprint />

        <div className="top-imprint">
          <div className="judul-imprint">
            <div className="logo">
              <img src="https://res.cloudinary.com/dhitaaaa/image/upload/v1741333837/Insan_Pustaka_Berkreasi_1-04_ao6e6a.png" alt="Logo" />
            </div>
            <div className="title-imprint">
              <h1>Insan Pustaka Berkreasi</h1>
              <p>Penerbit</p>
            </div>
          </div>
        </div>

        <div className="banner-container">
          {bannerImages.map((url, index) => (
            <img key={index} src={url} alt={`Banner ${index + 1}`} className="banner-image" />
          ))}
        </div>

        <div className="book-section">
          <h2>{filteredProducts.length} Produk</h2>

          <div className="booklist">
            {filteredProducts.map((product) => (
              <NavLink to="DetailImprint" key={product.id} className="detail-book">
                <div className="book-card">
                  <img src={product.image} alt={product.title} />
                  <h4>{product.title}</h4>
                  <p className="price">Rp {product.price.toLocaleString()}</p>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
