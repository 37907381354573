import React, { Component } from 'react';
import { Container, Button, Input } from 'reactstrap';
import { NavLink, withRouter } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
// import queryString from 'query-string';

// load trigger from index reducer action yang ditirigger setiap load page
import { getOrderCount } from "../../redux-store/redux-reducers";
import * as actionCreator from '../../redux-store/redux-actions/index';
import { ListCategoryCompany } from '../../api/index';






class HeaderImprint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backdrop: false,
            toggleMenu: false,
            toggleSubMenu: false,
            toggleMenuWeb: false,
            toggleSubMenuWeb: false,

            toggleCartDetail: false,
            toggleProfileDetail: false,
            query: null,
            categories: null,
            typingTimeout: 0
        }
    }

    fetchCategories = () => {
        const contents = {
        }

        ListCategoryCompany(contents).then(res => {
            this.setState({ categories: res })
        }).catch(err => console.log(err));
    }

    componentDidMount() {


        this.fetchCategories();
        this.props.retriveProductCart()// fetching product cart di store redux        
    }

    toggleMenuHandler = (event) => {
        event.stopPropagation(); //prevents propagation of the same event from being called
        this.setState({
            toggleMenu: !this.state.toggleMenu,
        })
        if (this.state.toggleSubMenu) {
            this.setState({ toggleSubMenu: false })
        }
    }

    toggleSubMenuHandler = (event) => {
        event.stopPropagation();
        this.setState({ toggleSubMenu: !this.state.toggleSubMenu })
    }

    subMenuClickHandler = (event) => {
        event.stopPropagation();
        this.setState({
            toggleMenu: false,
            toggleSubMenu: false
        })
    }

    // Menu Website

    mouseEnterMenuHandlerWeb = (event) => {
        event.stopPropagation(); 
        this.setState({mouseEnterMenu:             
            setTimeout(() => {
                this.setState({
                    toggleMenuWeb: true,
                    backdrop: true
                })
            }, 400)
        })       
    }

    mouseLeaveMenuHandlerWeb = (event) => {
        event.stopPropagation();        
        clearTimeout(this.state.mouseEnterMenu);        
    }

    onMouseEnterBackdrop = (e) => {        
        e.stopPropagation();      
        this.setState({
            toggleMenuWeb: false,
            toggleSubMenuWeb: false,
            backdrop: false,
            toggleCartDetail: false,
            toggleProfileDetail: false
        })
    }

    toggleSubMenuHandlerWeb = (event) => {
        event.stopPropagation();
        this.setState({
            toggleMenuWeb: true,
            toggleSubMenuWeb: true,
            backdrop: true
        })
    }

    cartMouseEnterHandler = (event) => {
        this.setState({
            toggleCartDetail: true,
            backdrop: true
        })
    }

    cartMouseClickHandler = (event) => {
        this.setState({
            toggleCartDetail: !this.state.toggleCartDetail,
            backdrop: !this.state.backdrop
        })
    }

    linkToProduct = (event, value) => {
        event.preventDefault();
        this.setState({ toggleCartDetail: false, backdrop: false })
        this.props.history.push(`/product/${value.id}-${value.name.replace(/\s+/g, '-').toLowerCase()}`)
    }

    DeleteCartProduct = (event, productId) => {
        event.stopPropagation();
        this.props.DeleteOrder(productId)
    }

    profileMouseEnterHandler = (event) => {
        this.setState({
            toggleProfileDetail: true,
            backdrop: true
        })
    }

    // toggleSubMenuHandler =(event)=> {
    //     event.stopPropagation();
    //     this.setState({toggleSubMenu:!this.state.toggleSubMenu})
    // }

    // subMenuClickHandler =(event) => {
    //     event.stopPropagation();
    //     this.setState({
    //         toggleMenu:false,
    //         toggleSubMenu:false
    //     })
    // }

    verifyToken = () => {
        const now = new Date();
        if (this.props.auth.expire !== null) {
            const jwtdate = new Date(this.props.auth.expire);
            if (jwtdate > now) {
                const token = this.props.auth.tokenId;
                const step = jwt.verify(token, "customertoken");
                return step.firstname
            }
        }
        else {
            return null
        }
    }

    onSearchHandler = (event) => {
        event.preventDefault();
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            query: event.target.value,
            typingTimeout: setTimeout(() => this.searchingKeyword(), 500)
        });

    }

    searchingKeyword = (e) => {
        const query = this.state.query;
        this.props.history.push('/product/searchimprint/?name=' + query + "&page=1")
    }

    onChangeSearchHandler = (event) => {
        event.preventDefault();
        this.setState({ query: event.target.value })
    }

    


    render() {
        let listProductInCart = null
        let firstname = this.verifyToken();
        let categories = null;

        if (this.state.categories !== null) {
            categories = this.state.categories.map((value, index) => (
                <li key={index} onMouseEnter={this.toggleSubMenuHandlerWeb} onClick={(event) => { this.onClickMenuHandler(event, value.id, value.name) }}>{value.name}<i className="fas fa-chevron-right"></i>
                    {/* <div className={this.state.toggleSubMenuWeb ? "dropdown-category-detail open-nav-website" : "dropdown-category-detail"}>
                        <ul>
                            <li>Detail 1</li>
                            <li>Detail 2</li>
                            <li>Detail 3</li>
                            <li>Detail 4</li>
                            <li>Detail 5</li>
                        </ul>
                    </div> */}
                </li>
            ))
        }

        if (this.props.productInCart.products.length > 0) {
            listProductInCart = this.props.productInCart.products.map((value, key) => (

                <div className="cart-list-product-wrapper" key={key} onClick={(event) => this.linkToProduct(event, value)}>
                    <div onClick={(event) => { this.DeleteCartProduct(event, value.id) }} className="del-cart-sum">x</div>
                    <div className="list-product-pict contain-pict" style={{ background: `url(${value.Pictures[0].url_small})` }} ></div>
                    <div className="product-list-desc-wrapper">
                        <div className="title">{value.name}</div>
                        {/* <div className="category">Kehutanan</div> */}
                        <div className="price">
                            <NumberFormat value={value.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} /> x {this.props.order[value.id]} =
                            <NumberFormat value={value.promoPrice * this.props.order[value.id]} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />

                        </div>
                    </div>
                </div>
            ))
        }

        return (
            <Container>
                <div className="header-imprint no print">
                    <div className="inner-container"> 
                    <div className="logo">
                            <NavLink to="/" style={{ textDecoration: 'none' }}>
                                <img className="logo-ipb" src="https://res.cloudinary.com/dhitaaaa/image/upload/v1741812951/logo-ipb-press_1_ewnxr0.jpg" alt="" />
                            </NavLink>
                            <span className="separator">x</span>
                                <img className='logo-imprint' src= "https://res.cloudinary.com/dhitaaaa/image/upload/v1741841642/Insan_Pustaka_Berkreasi_FIX_putih-01_qpl2pu.png"/>      
                        </div>
                        <div className="mobile-menu-button" >
                            <i onClick={this.toggleMenuHandler} className="fas fa-bars"></i>
                            <div className={this.state.toggleMenu ? "dropdown-category-mobile open-nav" : "dropdown-category-mobile"}>
                                <ul>
                                    {this.props.auth.isAuth ? (
                                        <NavLink to="/user/purchase" style={{ textDecoration: 'none' }}>
                                            <li onClick={() => { this.setState({ 
                                                toggleMenuWeb: false,
                                                toggleSubMenuWeb: false,
                                                toggleMenu: false,
                                                toggleSubMenu: false,
                                                backdrop: false,
                                             }) }}>Pesanan Saya</li>                                        
                                        </NavLink>
                                    ) : (
                                            <li onClick={this.props.OpenAuthModal}>Log In <i className="fas fa-chevron-right"></i> </li>
                                        )}

                                    <li onClick={this.toggleSubMenuHandler}>Kategori<i className="fas fa-chevron-right"></i>
                                        <div className={this.state.toggleSubMenu ? "dropdown-category-detail open-nav" : "dropdown-category-detail"}>
                                            <ul>
                                                {categories}
                                                {/* <li onClick={this.subMenuClickHandler}>Detail 1</li>
                                                <li>Detail 2</li>
                                                <li>Detail 3</li>
                                                <li>Detail 4</li>
                                                <li>Detail 5</li> */}
                                            </ul>
                                        </div>
                                    </li>
                                    {/* <li>Category 1 <i className="fas fa-chevron-right"></i></li>
                                    <li>Category 2 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 3 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 4 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 5 <i className="fas fa-chevron-right"></i> </li>
                                    <li onClick={this.toggleSubMenuHandler}>Category 6 <i className="fas fa-chevron-right"></i>
                                        <div className={this.state.toggleSubMenu ? "dropdown-category-detail open-nav" : "dropdown-category-detail"}>
                                            <ul>
                                                <li onClick={this.subMenuClickHandler}>Detail 1</li>
                                                <li>Detail 2</li>
                                                <li>Detail 3</li>
                                                <li>Detail 4</li>
                                                <li>Detail 5</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>Category 7 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 8 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 9 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 10 <i className="fas fa-chevron-right"></i> </li>
                                    <li>Category 11 <i className="fas fa-chevron-right"></i> </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="search-area">
                            <div onMouseEnter={this.mouseEnterMenuHandlerWeb} onMouseLeave={this.mouseLeaveMenuHandlerWeb} className="category-list">
                                <Button className="category-button" >
                                    Kategori
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                </Button>

                                <div className={this.state.toggleMenuWeb ? "dropdown-category-website open-nav-website" : "dropdown-category-website"}>
                                    <ul>
                                        {categories}
                                    </ul>
                                </div>
                            </div>

                            <div className="search-input">
                                {/* <form onSubmit={(event) => { this.onSearchHandler(event) }}> */}
                                <Input autoComplete="off" name="query" onChange={(event) => this.onSearchHandler(event)}
                                    placeholder="Cari Judul Buku, Deskripsi, Penulis"
                                ></Input>
                                {/* </form> */}
                            </div>
                        </div>
                        <div className="navigation-area">

                            {
                                this.props.auth.isAuth ? (
                                    <Button className="login-button" onClick={(event) => { this.profileMouseEnterHandler(event) }}><i className="fa fa-user" style={{ fontSize: '19pt', marginRight: '10px' }} aria-hidden="true"></i>{firstname}</Button>

                                ) : (
                                        <Button className="login-button" onClick={this.props.OpenAuthModal}>Log In</Button>
                                    )
                            }

                            {
                                this.props.auth.isAuth && this.state.toggleProfileDetail ? (

                                    <div className="user-menu-wrapper" onMouseLeave={(event) => { this.mouseLeaveMenuHandlerWeb(event) }}>
                                        <ul>
                                            {/* <NavLink to="/" style={{ textDecoration: 'none' }}>
                                                <li onClick={() => { this.setState({ backdrop: false, toggleProfileDetail: false }) }}>Profil Saya</li>
                                            </NavLink> */}
                                            <NavLink to="/user/purchase" style={{ textDecoration: 'none' }}>
                                                <li onClick={() => { this.setState({ backdrop: false, toggleProfileDetail: false }) }}>Pesanan Saya</li>
                                            </NavLink>
                                            <NavLink to="/logout" style={{ textDecoration: 'none' }}>
                                                <li onClick={() => { this.setState({ backdrop: false }) }}>
                                                    Keluar
                                                </li>
                                            </NavLink>
                                        </ul>
                                    </div>

                                ) : null
                            }


                            <Button className="cart-button" onMouseEnter={this.cartMouseEnterHandler} onClick={this.cartMouseClickHandler}>
                                <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                                <span className="count-in-cart-header">{this.props.productTypeCount}</span>
                            </Button>

                            {this.state.toggleCartDetail ? (
                                <div className="cart-dropdown-detail" onMouseLeave={this.mouseLeaveMenuHandlerWeb}>
                                    <div className="cart-dropdown-header">
                                        {this.props.productTypeCount === 0 ? (
                                            <div>Belum ada barang yang ditambahkan</div>

                                        ) : (
                                                <div>Anda memiliki {this.props.productTypeCount} Produk di Troli</div>
                                            )}
                                    </div>
                                    <div className="cart-dropdown-body">
                                        {listProductInCart}
                                    </div>
                                    <div className="cart-dropdown-footer">
                                        <div className="cart-foot-wrapper">
                                            <div className="cd-foot-label-tot">total</div>
                                            <div className="cd-foot-label-num">
                                                <NumberFormat value={this.props.productTotalPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                            </div>
                                        </div>
                                        <div className="cart-foot-checkout-wrapper">
                                            {this.props.productTypeCount > 0 ? (
                                                <NavLink to="/checkout" style={{ textDecoration: 'none' }}>
                                                    <Button onClick={() => { this.setState({ backdrop: false, toggleCartDetail: false }) }} className="cta-btn-checkout" >
                                                        <span>Lanjut Pembayaran <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i></span>
                                                    </Button>
                                                </NavLink>
                                            ) :
                                                null}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                        </div>
                    </div>
                </div>
                {this.state.backdrop ? (<div onMouseEnter={this.onMouseEnterBackdrop} className="backdrop"></div>) : null}
            </Container>

        )
    }
}

const mapStateToProps = state => {
    return {
        order: state.order,
        productInCart: state.products,
        productTypeCount: getOrderCount(state),
        productTotalPrice: state.products.totalPrice,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        retriveProductCart: () => dispatch(actionCreator.retriveProductCart()),
        DeleteOrder: (ProductId) => dispatch(actionCreator.deleteOrderHandler(ProductId)),
        OpenAuthModal: () => dispatch(actionCreator.openModalAuth())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderImprint));