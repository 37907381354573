import React from "react";
import { useEffect } from "react";

const services = [
  { name: "Percetakan", desc: "IPB Printing", link:"https://www.ipbprinting.id/c/percetakan", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812953/percetakan_lkrt3t.png"},
  { name: "Jurnal Paper", desc: "IPB Press", link:"https://ipbpress.my.id/IPB-Press-Journal-Partners", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812948/jurnalpaper_oo57fw.png"},
  { name: "Penjualan Buku", desc: "Bookstore (IPB Printing)",link:"https://www.ipbprinting.id/c/buku-pilihan", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812952/penjualanbuku_xxwi5t.png"},
  { name: "Jasa Penerbitan", desc: "IPB Printing",link:"https://www.ipbprinting.id/c/jasa-penerbitan", image:"https://res.cloudinary.com/dhitaaaa/image/upload/v1741812952/penerbitan_oo18l3.png"},
  { name: "Merchandise", desc: "IPB Printing",link:"https://www.ipbprinting.id/c/merchandise", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812950/merchandise_ftzfkd.png"},
  { name: "IPB Digistore", desc: "Digistore",link:"https://ipbpressdigistore.com/", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812948/digistore_mg482m.png"},
];

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <div className="about-us">
      {/* Bagian Video Background */}
      <div className="main">
        <div className="video">
          <video src="https://res.cloudinary.com/dhitaaaa/video/upload/v1741813005/videoBg_d7gsvh.mp4" autoPlay loop muted />
        </div>
        <div className="overlay">
          <div className="content">
          <h1>Tentang IPBPRESS</h1>
          <p1>"We Provide Solutions"</p1>
          </div>
        </div>     
      </div>

      <div className="background">
        {/* Deskripsi */}
        <div className="description">
          {/* <p className="subHeading">Tentang kami</p> */}
          <div className="descdetail">
          <h2>Apa itu IPBPRESS?</h2>
          <p>
            IPB Press adalah penerbit universitas berbadan hukum PT yang telah bergabung dengan IKAPI dan APPTI. 
            Berdiri sejak 6 April 2009 dan diakuisisi oleh BLST pada 2014, IPB Press menjadi University Press pertama di Indonesia dengan status PT.
            IPB Press juga menghadirkan buku dalam format cetak, e-book, dan audio-book, serta terus meningkatkan layanan untuk memberikan yang terbaik bagi pelanggan.
          </p>
          </div> 
          <div className="video-box">
            <iframe 
              src="https://www.youtube.com/embed/W31NCUTvNcc" 
              frameborder="0" 
              allowfullscreen>
            </iframe>
          </div>   
        </div>
        </div>

        {/* Layanan Kami */}
          <div className="services">
              <h2>Layanan Kami</h2>
              <div className="service-container">
                  {services.map((service, index) => (
                  <a key={index} href={service.link} target="_blank" rel="noopener noreferrer" className="service-box">
                    <img src={service.image} alt={service.name} className="service-image"/>
                      <h3>{service.name}</h3>
                      <p>{service.desc}</p>
                  </a>
            ))}
              </div>
          </div>
          
          <div className="more-info">
              <img src= "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812955/why-us_ah3dz1.jpg" alt="Kenapa Kami" className="info-image"/>
              <div className="info-text">
              <h2>Kenapa Kami?</h2>
              <p>
              IPB Press terus berinovasi untuk menyediakan layanan terbaik di dunia penerbitan dan percetakan.
              Kami berkomitmen untuk mendukung dunia pendidikan dengan menyediakan buku dan media pembelajaran berkualitas.
              </p>
              <ul>
              <li>Kualitas Cetak dan Penerbitan Terbaik</li>
              <li>Kemudahan bagi penulis  dan </li>
              <li>Layanan yang Cepat dan Mudah</li>
              <li>Pilihan Buku dan Merchandise yang Beragam</li>
              </ul>
          </div>
              
          </div>

          <div className="visi-misi">
              <h2>Tujuan</h2>
              <div className="visi">
                  <h3>Visi</h3>
                  <blockquote>
                      <p><span className="quote">“</span> Menjadi Penerbit universitas yang unggul dalam dunia literasi
                                                          untuk menginspirasi kehidupan berkelanjutan dengan menjunjung 
                                                          tinggi nilai integritas dan inovasi di bidang pertanian, kelautan,
                                                          dan biosains tropika. <span className="quote">”</span></p>
                  </blockquote>
              </div>
              <div className="misi">
                  <h3>Misi</h3>
                  <blockquote>
                      <p><span className="quote">“</span> IPB Press berkomitmen untuk menjaga semangat, konsistensi, dan
                                                          persistensi dalam menerbitkan buku berkualitas. Kami bersinergi 
                                                          dengan warga IPB dan lembaga lain untuk menghadirkan karya terbaik, 
                                                          serta berkolaborasi dengan dunia usaha dan industri guna mengoptimalkan 
                                                          manfaat buku bagi penulis dan pembaca. Selain itu, IPB Press memberikan 
                                                          apresiasi komersial bagi penulis dan stakeholder, serta terus menghadirkan 
                                                          buku dalam berbagai format, seperti cetak, e-book, dan audio-book, guna menginspirasi
                                             dunia literasi. Dengan layanan yang optimal, kami berupaya mewujudkan service excellence 
                                                          bagi pelanggan. <span className="quote">”</span></p>
                  </blockquote>
              </div>
          </div>

          <div className="contact-center">
              <h2>Hubungi Kami</h2>
              <p>
                  Jika Anda memiliki pertanyaan atau ingin bekerja sama dengan kami, silakan hubungi kami melalui: 
              </p>
              <div className="contact-wrapper">
                <div className="contact-left">
                  <ul>
                    <li>📧 : <a href="mailto:ipbpress@apps.ipb.ac.id">penerbit.ipbpress@gmail.com</a></li>
                    <li>📞 :  Telepon: +6281112300434</li>
                  </ul>
                </div>

                <div className="contact-right">
                  <ul>
                    <li>📍 Alamat: Science and Techno Park - IPB University, RT.03/RW.03, Babakan, Kecamatan Bogor Tengah, Kota Bogor, Jawa Barat</li>
                  </ul>
                </div>
              </div>
          </div> 
    
        
  </div>
  );
};

export default AboutUs;
