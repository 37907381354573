import React, { Component } from "react";
import { Container, Button } from "reactstrap";
import NumberFormat from "react-number-format";
import HeaderImprint from '../../components/Header/HeaderImprint';

class DetailImprint extends Component {
  state = {
    book: {
      title: "Detail Kecil",
      price: 79000,
      discountedPrice: 69520,
      discount: "-12%",
      author: "Adania Shibli",
      weight: "185 g",
      length: "23 cm",
      width: "15 cm",
      pages: 174,
      year: 2014,
      description:
        "Mungkin memang tidak ada hal yang lebih penting dari detail kecil seperti ini. Detail kecil yang bisa menjadi jalan untuk mengetahui kebenaran utuh tentang kisah si gadis. Detail kecil yang sayangnya justru tidak diungkap dalam artikel di koran pagi itu. Di Palestina, yang luar biasa—rudal, gedung hancur, dan mayat—sudah jadi biasa. Dari situlah, novel ini mengantarkan pembaca melihat Palestina lewat “detail kecil” yang kerap disepelekan. Lewat beda debit air mandi antara di Israel dan Palestina, lewat berita pemerkosaan gadis Badui tanpa nama setahun setelah Nakba 1948. Melalui tuturan dua naratornya—tentara Israel dan pegawai Palestina—Shibli menyuguhkan jeroan hati para tokohnya sekaligus mengekspos bagaimana penjajah bekerja.",
      images: [
        "https://res.cloudinary.com/dhitaaaa/image/upload/v1741675967/Screenshot_2025-03-11_134041_rl2oux.png",
        "https://res.cloudinary.com/dhitaaaa/image/upload/v1741675968/Screenshot_2025-03-11_134128_x8jxqs.png",
        "https://res.cloudinary.com/dhitaaaa/image/upload/v1741675968/Screenshot_2025-03-11_134211_nkiozz.png",
        "https://res.cloudinary.com/dhitaaaa/image/upload/v1741675968/Book-display.png",
      ],
    },
    selectedImageIndex: 0,
    quantity: 1, 
  };

  componentDidMount() {
    this.startSlideshow();
  }

  startSlideshow = () => {
    this.slideshowInterval = setInterval(() => {
      this.setState((prevState) => ({
        selectedImageIndex:
          (prevState.selectedImageIndex + 1) % prevState.book.images.length,
      }));
    }, 6000); // Ganti gambar setiap 6 detik
  };

  componentWillUnmount() {
    clearInterval(this.slideshowInterval);
  }

  changeImage = (index) => {
    this.setState({ selectedImageIndex: index });
  };

  increaseQuantity = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1,
    }));
  };

  decreaseQuantity = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity > 1 ? prevState.quantity - 1 : 1, // Minimum 1
    }));
  };

  render() {
    const { book, selectedImageIndex, quantity } = this.state;
    const selectedImage = book.images[selectedImageIndex];

    return (
      <Container className="detail-imprint">
  <HeaderImprint/>
  
  <div className="container"> 
    <div className="book-container">
      <div className="book-display">
        <div className="book-image">
          <img src={selectedImage} alt="Book Cover" />
        </div>
        <div className="book-thumbnails">
          {book.images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Thumbnail ${index}`}
              onClick={() => this.changeImage(index)}
              className={selectedImageIndex === index ? "active" : ""}
            />
          ))}
        </div>
      </div>

      <div className="book-info">
        <p className="title-book">{book.title}</p>
        <p className="author">Penulis: {book.author}</p>
        <div className="price-section">
          <span className="discounted-price">
            <NumberFormat
              value={book.discountedPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
            />
          </span>
          <span className="original-price">
            <NumberFormat
              value={book.price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rp "}
            />
          </span>
          <span className="discount">{book.discount}</span>
        </div>

        <div className="quantity-selector">
          <span className="label">Kuantitas:</span>
          <div className="quantity-controls">
            <button className="decrease" onClick={this.decreaseQuantity}>-</button>
            <span className="quantity">{quantity}</span>
            <button className="increase" onClick={this.increaseQuantity}>+</button>
          </div>
        </div>

        <div className="button-group">
          <Button className="cart-button">Masukkan Keranjang</Button>
          <Button className="buy-button">Beli Sekarang</Button>
        </div>
      </div>
    </div>

    <div className="book-details">
      <div className="details-container">
      <div className="specifications">
        <p><strong>Berat:</strong> {book.weight}</p>
        <p><strong>Panjang:</strong> {book.length}</p>
        <p><strong>Lebar:</strong> {book.width}</p>
        <p><strong>Halaman:</strong> {book.pages}</p>
        <p><strong>Tahun Terbit:</strong> {book.year}</p>
      </div>

      <div className="description">
        <h3>Deskripsi</h3>
        <p>{book.description}</p>
      </div>
      </div>
    </div>
  </div>
</Container>

    );
  }
}

export default DetailImprint;
