import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap'



class Footer extends Component {
    constructor(props){
        super(props);
        this.state= {
            belanja: false,
            jelajah:false,
            pembayaran:false,
            pengiriman:false,
            ikutikami:false
        }
    }

    onCollapseHandler=(event, value)=>{
        event.preventDefault();
        this.setState({[value]:!this.state[value]})
    }


    render() {
        
        return (
            <Container>
                <div className="footer-wrapper no-print">
                    <div className="inner-footer-wrapper">
                        <div className="footer-cl-1 footer-column">
                            <h4>
                                <div className="collapse-button-trigger" onClick={((event)=>{this.onCollapseHandler(event,'belanja')})}></div>
                                Belanja
                            </h4>
                            <ul className={this.state.belanja ? "zero-height" : ''}>
                                <li>Cara Berbelanja</li>
                                <li>Pembayaran</li>
                                <li>Pengiriman</li>
                            </ul>
                        </div>
                        <div className="footer-cl-2 footer-column">
                            <h4>
                                <div className="collapse-button-trigger" onClick={((event)=>{this.onCollapseHandler(event,'jelajah')})}></div>
                                Jelajah Kami
                            </h4>
                            <ul className={this.state.jelajah ? "zero-height" : ''}>
                                <NavLink to="/about-us"><li>Tentang Kami</li></NavLink> 
                                <a style={{textDecoration:'none'}} href="http://careers.blst.co.id" rel="noopener noreferrer" target="_blank"><li>Karir</li></a>
                                <li>Kebijakan IPB Book Store</li>
                                <li>Kebijakan Privasi</li>
                                <li>Blog</li>
                                <NavLink to="/product/perpusnas/?name=isbn&page=1&minprice=0&maxprice=500000"><li>Kerja Sama</li></NavLink>
                                {/* <li>Flash Sale</li> */}
                            </ul>
                        </div>
                        <div className="footer-cl-3 footer-column">
                            <h4>
                                <div className="collapse-button-trigger" onClick={((event)=>{this.onCollapseHandler(event,'pembayaran')})}></div>
                                Pembayaran
                            </h4>
                            <div className="payment-logo">
                                <ul className={this.state.pembayaran ? "zero-height" : ''}>
                                    <li>
                                        <div className="footer-background-logo footer-bank_bri-png"></div>
                                    </li>
                                    {/* <li>
                                        <div className="footer-background-logo footer-bca-png"></div>
                                    </li>
                                    <li>
                                        <div className="footer-background-logo footer-bni-png"></div>
                                    </li> */}
                                </ul>
                            </div>

                            <h4>
                                <div className="collapse-button-trigger" onClick={((event)=>{this.onCollapseHandler(event,'pengiriman')})}></div>
                                Pengiriman
                            </h4>
                            <div className="payment-logo">
                                <ul className={this.state.pengiriman ? "zero-height" : ''}>
                                    <li>
                                        <div className="footer-background-logo footer-jne-png"></div>
                                    </li>
                                    <li>
                                        <div className="footer-background-logo footer-jnt-png"></div>
                                    </li>
                                    {/* <li>
                                        <div className="footer-background-logo footer-gosend-png"></div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="footer-cl-4 footer-column">
                            <h4>
                                <div className="collapse-button-trigger" onClick={((event)=>{this.onCollapseHandler(event,'ikutikami')})}></div>
                                Ikuti Kami
                            </h4>
                            <ul className={this.state.ikutikami ? "zero-height" : ''}>
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100086165379402" target="_black">
                                        <i className="fab fa-facebook-square"></i> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/ipbpress.official/">
                                        <i className="fab fa-instagram"></i> Instagram
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/c/IPBPRESS1">
                                        <i className="fab fa-youtube"></i> Youtube
                                    </a>
                                </li>
                                <li>
                                    <a href="https://vt.tiktok.com/ZSRY6gdwf/">
                                        <i className="fab fa-tumblr"></i> Tiktok
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/ipb-press/">
                                        <i className="fab fa-linkedin"></i> Linkedin
                                    </a>
                                </li>

                            </ul>

                            {/* WhatsApp icon */}
      <a
        href="https://wa.me/+628111230434"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
                        </div>
                    </div>
                </div>

            </Container>
        )
    }
}

export default Footer;