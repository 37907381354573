import React, { Component } from 'react';
import SliderFront from '../../components/SliderFront/SliderFront';
import ProductSlider from '../../components/ProductSlider/ProductSlider';
import { SearchQuery, SearchJurnal, SearchEbook, SearchImprint } from '../../api/index';
import queryString from 'query-string'
import { NavLink } from 'react-router-dom';



class FrontPage extends Component {
    constructor(props) {
        super(props)

        this.state = {   //menyimpan data API
            bestSeller: [],           
            mostwanted:[],
            newest:[],
            highestprice:[],
            lowestprice:[],
            diskon:[],
            newproduct:[],
            store: []
        }
    }
    //API buat store nanti 
    // fetchStoreData = () => {
    //     fetch("URL_API_STORE") 
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log("Store API Response:", data);
    //             const formattedData = data.map(item => ({
    //                  name: item.title, 
    //                  desc: item.description,
    //                  link: item.url,
    //                  image: item.image_url
    //              }));
    //             this.setState({ store: data });
    //         })
    //         .catch(error => console.error("Error fetching store data:", error));
    // };    

    fetchProduct = (payload,sortby) => {  //bagaimana data diambil
        SearchQuery(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    fetchProductJurnal = (payload,sortby) => {
        SearchJurnal(payload).then(result => {           
            this.setState({
                [sortby]: result.result,                
            })
        });
    }

    fetchProductEbook = (payload, sortby) => {
        SearchEbook(payload).then(result => {
            this.setState({
                [sortby] : result.result
            })
        });
    }



    fetchProductImprint = (payload, sortby) => {
        SearchImprint(payload).then(result => {
            this.setState({
                [sortby] : result.result
            })
        });
    }

    doingSearch = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProduct(content,sortby);
    }



    doingSearchJurnal = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProductJurnal(content,sortby);
    }

    doingSearchEbook = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProductEbook(content,sortby);
    }



    doingSearchImprint = (sortby) => {
        this.setState({ isFetching: true })
        const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

        let byName = '';
        let byminPrice = '';
        let bymaxPrice = '';
        let byCategory = '';
        let bySort = sortby;
        let page = '';

        if (query.name !== undefined) {
            byName = query.name ? query.name : '';
            byminPrice = query.minprice ? query.minprice : '';
            bymaxPrice = query.maxprice ? query.maxprice : '';
            byCategory = query.category ? query.category : '';
            bySort = query.sortby ? query.sortby : '';
            page = query.page ? query.page : '';
            this.setState({ queryName: byName });
        }

        // jika klik dari menu
        if (this.props.match.params.nameCat !== undefined) {
            byCategory = this.props.match.params.nameCat
            page = query.page ? query.page : 1;
            this.setState({ queryName: "Kategori " + byCategory })
        }

        this.setState({ page: Math.ceil(query.page) })

        const content = {
            name: byName,
            minprice: byminPrice,
            maxprice: bymaxPrice,
            category: byCategory,
            sortby: bySort,
            page: page
        }

        this.fetchProductImprint(content,sortby);
    }







    // doingSearchWakaf(){
    //     this.setState({ isFetching: true })
    //     const query = queryString.parse(this.props.location.search); // ?name=&category=&babibu=

    //     let byName = '';
    //     let byminPrice = '';
    //     let bymaxPrice = '';
    //     let byCategory = 'wakaf';
    //     let bySort = 'lowestprice';
    //     let page = '';

    //     if (query.name !== undefined) {
    //         byName = query.name ? query.name : '';
    //         byminPrice = query.minprice ? query.minprice : '';
    //         bymaxPrice = query.maxprice ? query.maxprice : '';
    //         byCategory = query.category ? query.category : '';
    //         bySort = query.sortby ? query.sortby : '';
    //         page = query.page ? query.page : '';
    //         this.setState({ queryName: byName });
    //     }

    //     // jika klik dari menu
    //     if (this.props.match.params.nameCat !== undefined) {
    //         byCategory = this.props.match.params.nameCat
    //         page = query.page ? query.page : 1;
    //         this.setState({ queryName: "Kategori " + byCategory })
    //     }

    //     this.setState({ page: Math.ceil(query.page) })

    //     const content = {
    //         name: byName,
    //         minprice: byminPrice,
    //         maxprice: bymaxPrice,
    //         category: byCategory,
    //         sortby: bySort,
    //         page: page
    //     }       

    //     this.fetchProduct(content,'wakaf');
    // }

    componentDidMount() {
        this.doingSearch('newest');
        this.doingSearch('bestSeller');
        this.doingSearch('lowestprice');
        this.doingSearch('mostwanted');
        this.doingSearch('diskon');
        this.doingSearch('newproduct');
        this.doingSearchJurnal('newest');
        this.doingSearchEbook('highestprice');
        this.doingSearchImprint('highestprice');

    //this.fetchStoreData(); --> ini kalau API nya udh ada
        
    // Data dummy store sebelum ada API
       this.setState({
        store: [
            { desc: "Imprint",  link:"/product/searchimprint/?name=&page=1&sortby=newproduct", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741333837/Insan_Pustaka_Berkreasi_1-04_ao6e6a.png",},
            { desc: "IPB Printing", link:"https://www.ipbprinting.id/c/percetakan", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812953/percetakan_lkrt3t.png"},
            { desc: "IPBDigistore", link:"https://ipbpressdigistore.com", image: "https://res.cloudinary.com/dhitaaaa/image/upload/v1741812948/digistore_mg482m.png"},
        ]
    });
    
        // Program Wakaf
        // this.doingSearchWakaf();
     

    }

    render() {
        return (
            <div className='bg-gray-100 min-h-screen'>
                <SliderFront />
                <div className="store">
                    <h2>Official Store</h2>
                    <div className="store-container">
                        {this.state.store.map((store, index) => (
                            <a 
                            key={index} 
                            href={store.link} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className={`store-box ${store.desc === "Imprint" ? "store-imprint" : "store-other"}`}
                          >
                            <div className={store.desc === "Imprint" ? "icon-imprint" : "icon-other"}>
                              <img src={store.image} alt={store.desc} className="store-image" />
                            </div>
                            <p>{store.desc}</p>
                          </a>
                        
                    ))}
                    </div>
                </div>
                <div className="slider">
                <ProductSlider
                    title="Harga Spesial!" 
                    category="diskon"    
                    products={this.state.diskon}
                    hyperlink="/product/search/?name=&page=1&sortby=diskon"
                    //thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139569/buku-buku-diskon.jpg"
                />
                <ProductSlider
                    title="Koleksi Terbaru"
                    category="baru"
                    products={this.state.newproduct}
                    hyperlink="/product/newproduct/?name=&page=1&sortby=newproduct"
                    //thumbnail="https://res.cloudinary.com/blst/image/upload/v1654139568/buku-buku-terbaru.jpg"
                />
                <ProductSlider
                    title="Pilihan Teratas"
                    category="populer"
                    products={this.state.lowestprice}
                    hyperlink="/product/search/?name=&page=1&sortby=lowestprice"
                    //thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935148/slider/buku-terpopuler-ipbpress.jpg"
                />
                <ProductSlider
                    title="Sedang Tren"
                    category="dicari"
                    products={this.state.mostwanted}
                    hyperlink="/product/search/?name=&page=1&sortby=mostwanted"
                    //thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935146/slider/buku-palingdicari-ipbpress.jpg"
                />
                <ProductSlider
                    title="Best Seller!"
                    category="laris"
                    products={this.state.bestSeller}
                    hyperlink="/product/search/?name=&page=1&sortby=bestSeller"
                    //thumbnail="https://res.cloudinary.com/blst/image/upload/v1551935149/slider/buku-terlaris-ipbpress.jpg"
                />
                 <ProductSlider
                    title="Katalog Jurnal"
                    category="jurnal"
                    products={this.state.newest}
                    hyperlink="/product/jurnalproduct/?name=&page=1&sortby=newproduct"
                    //thumbnail="https://res.cloudinary.com/blst/image/upload/v1685345491/SideBar_Jurnal_5_.png"
                />
                {/* <ProductSlider
                    title="E-Book"
                    products={this.state.highestprice}
                    hyperlink="/product/searchimprint/?name=&page=1&sortby=newproduct"
                    // thumbnail="https://res.cloudinary.com/blst/image/upload/v1694666609/WhatsApp_Image_2023-09-14_at_11.06.00.jpg"
                /> */}

                </div>
                
            </div>
        )
    }
}

export default FrontPage;