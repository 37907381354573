import React, { Component } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../services/PrivateRoute";
import * as actionCreator from '../redux-store/redux-actions/index';


import FrontPage from '../views/FrontPage/FrontPage';
import Imprint from '../views/Store/Imprint';
import DetailProduct from '../views/DetailProduct/DetailProduct';
import DetailImprint from '../views/DetailProduct/DetailImprint';
import ProductSearch from '../views/ProductSearch/ProductSearch';
import ProductPerpusnas from '../views/ProductPerpusnas/ProductPerpusnas';
import Checkout from '../views/Checkout/Checkout';


import LoginRegister from '../components/LoginRegister/LoginRegister';
import { connect } from 'react-redux';
import SignUpLogIn from '../views/SignUpLogIn/SignUpLogIn';
import Logout from '../services/Logout';
import Thanks from '../views/Thanks/Thanks';
import FailedPayment from '../views/FailedPayment/FailedPayment';
import ProfilePage from '../views/ProfilePage/ProfilePage';
import OrderDetail from '../views/OrderDetail/OrderDetail';
import Invoice from '../views/OrderDetail/Invoice/Invoice';
import { Helmet } from "react-helmet";
import ProductNew from '../views/ProductNew/ProductNew';
import ProductJurnal from '../views/ProductNew/ProductJurnal';
import ProductEbook from '../views/ProductNew/ProductEbook';
import ReadEbook from '../views/ReadEbook/ReadEbook';
import AboutUs from '../views/AboutUs/AboutUs'
import ProductImprint from '../views/ProductNew/ProductImprint';


class StoreLayout extends Component {

    
    render() {
        return (
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>IPB PRESS BOOKSTORE</title>
                <meta name="description" content=" PT IPB Press hadir sejak tahun 2009 sebagai perusahaan 
                penerbitan yang memiliki fokus menyalurkan ilmu dan hasil riset penelitian kepada masyarakat 
                luas agar tercipta kemajuan industri pertanian, kelautan, dan biosains tropika yang berdaya saing dan berkelanjutan."></meta>
                <meta name="author" content="IPB PRESS"></meta>
                

                <meta property="og:title" content="IPB PRESS BOOKSTORE"></meta>
                <meta property="og:url" content="ipbpress.com"></meta>
                <meta property="og:author" content="IPB PRESS"></meta>             
            </Helmet>
            <div className="wrapper">
                {this.props.isModalAuthOpen ? (
                    <LoginRegister />
                ) : null}
                <Header />
                <div className="main-page">
                    <Switch>
                        <Route path="/" exact component={FrontPage}></Route>
                        <Route path="/imprint"exact component={Imprint}></Route>
                        <Route path="/product/search" component={ProductSearch}></Route>
                         <Route path="/product/newproduct" component={ProductNew}></Route>
                         <Route path="/product/jurnalproduct" component={ProductJurnal}></Route>
                         <Route path="/product/searchebook" component={ProductEbook}></Route>
                         <Route path="/product/searchimprint" component={ProductImprint}></Route>
                        <Route path="/product/category/:nameCat" component={ProductSearch}></Route>

                        {/* <Route path="/readebook" exact component={ReadEbook}></Route> */}
                       
                        <Route path="/product/:id" exact component={DetailProduct}></Route>
                        <Route path="/signup-login" exact component={SignUpLogIn}></Route>
                        <Route path="/logout" exact component={Logout}></Route>
                        <Route path="/about-us"exact component={AboutUs}></Route>
                        <Route path="/detail-imprint"exact component={DetailImprint}></Route>
                        

                        <PrivateRoute path="/checkout" exact isAuth={this.props.auth} component={Checkout}></PrivateRoute>
                        <PrivateRoute path="/checkout/thanks/:id" exact isAuth={this.props.auth} component={Thanks}></PrivateRoute>
                        {/* <PrivateRoute path="/payment/failed/:id" exact isAuth={this.props.auth} component={FailedPayment}></PrivateRoute> */}
                        <PrivateRoute path="/user" isAuth={this.props.auth} component={ProfilePage}></PrivateRoute>
                        <PrivateRoute path="/order/detail/:id" exact isAuth={this.props.auth} component={OrderDetail}></PrivateRoute>
                        <PrivateRoute path="/order/invoice/:id" exact isAuth={this.props.auth} component={Invoice}></PrivateRoute>
                        <PrivateRoute path="/order/readebook/:idtransaction/:idproduct" exact isAuth={this.props.auth} component={ReadEbook}></PrivateRoute>
                    </Switch>

                </div>
                <Footer />
                <div>
                    <Route path = "/">

                    </Route>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isModalAuthOpen: state.auth.modalShow,
        auth:state.auth.isAuth
    }
}

const mapDispatchToProps = dispatch => {
    return {
       OpenAuthModal: ()=>dispatch(actionCreator.openModalAuth())
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(StoreLayout);